import { useMutation, useQuery } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import React, { useEffect, useState } from 'react';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { isMobile } from 'mobile-device-detect';
import { remoteStorage } from '../../utils/remoteStorage';

import {
  audioSprite,
  audioSpriteVolume,
  LOADER_MAPPED_SYMBOLS,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
} from '../../config';
import {
  bonusesId,
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
} from '../../global.d';
import {
  authGql,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  setBonuses,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setIsAuthorized,
  setIsSoundOn,
  setIsSuspended,
  setProgress,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsTurboSpin,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  slotHistoryGql,
  userBonusBetsGql,
  betsByInitialRoundId,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setReplayBet,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import {
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
} from '../../utils';
import Resources from '../../utils/resources';
import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });
      if (replayBetFreeSpins.data.betsByInitialRoundId.length) {
        const replayFreeSpins =
          replayBetFreeSpins.data.betsByInitialRoundId.map((e) => e.id);
        setReplayFreeSpinBets(replayFreeSpins);
        setReplayFreeSpinReelSetId(
          replayBetFreeSpins.data.betsByInitialRoundId[0].reelSetId,
        );
      }
    };

    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGame = async () => {
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      if (userBonusData.data.userBonuses.length > 0) {
        setBrokenGame(true);
        const freeSpinsBonus = userBonusData.data.userBonuses[0];
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
        });
        const totalWinAmount = freeSpinsBonus.totalWinAmount
          ? freeSpinsBonus.totalWinAmount / freeSpinsBonus.coinValue
          : 0;
        setCurrentFreeSpinsTotalWin(totalWinAmount);
        setCurrentBonusId(setCurrentBonus().id);
        const userBonusBetsData = await client.query<{
          bets: ISlotHistoryData;
        }>({
          query: userBonusBetsGql,

          variables: {
            input: {
              filter: {
                userBonusId: setCurrentBonus().id,
              },
            },
          },
          fetchPolicy: 'network-only',
        });
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: userBonusBetsData.data.bets.pageInfo.count,
          rounds:
            setCurrentBonus().rounds +
            userBonusBetsData.data.bets.pageInfo.count,
          totalWinAmount: 0,
        });
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, 'auth', async (stage) => {
        const { token, clientId } =
          parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({
          variables: { input: { token, clientId } },
        });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }

        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [
            ...LOADER_MAPPED_SYMBOLS,
            ...LOADER_TEXTURES(isMobile),
            ...SPINE_LOADER_TEXTURES(isMobile),
          ],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(
          audioSprite,
          audioSpriteVolume,
          setSkipIntroScreen(),
          isSoundOn,
          setIsSuspended,
        ).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioHowl.isRestricted &&
              !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          wasLoaded: setSkipIntroScreen(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })

      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
